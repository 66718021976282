import { derived, type Readable, writable, type Writable } from 'svelte/store';
import type { Action, HistoryItem, HistoryStore, ProductInfo } from './types';
import { browser } from '$app/environment';
import { PUBLIC_MAX_AGE_HISTORY_ITEM } from "$env/static/public";

export class HistoryStoreImpl implements HistoryStore {
  private static _instance: HistoryStoreImpl;

  private _localStorageKey = 'userHistory';
  private _history: Writable<HistoryItem[]>;
  private _productCodeForCourseSlide: string | null = null;

  private constructor() {
    if (browser) {
      this._history = writable(this._getHistoryFromLocalStorage());
      this._history.subscribe((currentValue) => this._saveHistoryToLocalStorage(currentValue));
      this._history.subscribe((currentValue) => this._checkProductCodeForCourseSlide(currentValue));
    } else {
      this._history = writable([]);
    }
  }

  /**
   * Add a new entry based on the action and the session related to
   * @param action The action performed: e.g 'view', 'download_brochure', etc...
   * @param productInfo The product info
   */
  addHistoryEntry(action: Action, productInfo: ProductInfo) {
    this._history.update((value) => {
      if (this._existsInHistory(value, { action, productInfo })) return value;
      return [
        ...value,
        {
          date: new Date(),
          action: action,
          product: productInfo,
        },
      ];
    });
  }

  getProductCodeForCourseSlide(): string | null {
    return this._productCodeForCourseSlide;
  }
  /**
   * A readable store of the history
   */
  public get history(): Readable<HistoryItem[]> {
    return derived(this._history, (value) => value);
  }

  /**
   * A readable store of the history of CTAs performed
   */
  public get historyOfCTAs(): Readable<HistoryItem[]> {
    return derived(this._history, (value) => value.filter((item) => item.action !== 'view'));
  }

  /**
   * A readable store of the history of products views
   */
  public get historyOfProductViews(): Readable<HistoryItem[]> {
    return derived(this._history, (value) => value.filter((item) => item.action === 'view'));
  }

  /******* PRIVATE METHODS (HELPERS) *******/

  private _existsInHistory(history: HistoryItem[], itemToCheck: { action: Action; productInfo: ProductInfo }) {
    return history.some(
      (item) => item.action === itemToCheck.action && item.product.productCode === itemToCheck.productInfo.productCode
    );
  }

  /**
   * Check If history contains something useful for the CourseSlide HP item
   * @param history The history to save
   */

  private _checkProductCodeForCourseSlide(history: HistoryItem[]) {
    const maxAgeInDays = parseInt(PUBLIC_MAX_AGE_HISTORY_ITEM);

    let firstChoice: string | null = null;
    let secondChoice: string | null = null;

    for (let historyItem of history.slice().reverse()) {
      if (firstChoice === null && (historyItem.action === 'download_brochure' || historyItem.action === 'request_info')) {
        if (maxAgeInDays) {
          // We have a max age flag so use it
          const today = new Date();
          const historyItemDate = historyItem.date;
          historyItemDate.setDate(historyItemDate.getDate() + maxAgeInDays);
          if (historyItemDate.getTime() > today.getTime()) {
            firstChoice = historyItem.product.productCode;
            break;
          }
        } else {
          firstChoice = historyItem.product.productCode;
          break;
        }
      }

      /*if(secondChoiche === null && historyItem.action === "admission"){
        secondChoiche = historyItem.product.productCode;
      }
      if(firstChoice !== null && secondChoiche!==null){
        break;
      }*/
    }
    this._productCodeForCourseSlide = firstChoice;
  }

  /**
   * Saves the history to local storage
   * @param history The history to save
   */
  private _saveHistoryToLocalStorage(history: HistoryItem[]) {
    localStorage.setItem(this._localStorageKey, JSON.stringify(history));
  }

  /**
   * Retrieves the history from local storage
   * @returns The history saved in local storage or an empty if none exist
   */
  private _getHistoryFromLocalStorage(): HistoryItem[] {
    const historyStr = localStorage.getItem(this._localStorageKey);
    let history: HistoryItem[] = [];
    let historyRaw: any[] = [];
    try {
      historyRaw = historyStr ? JSON.parse(historyStr) : [];
      if (historyRaw !== null) {
        history = historyRaw.map((item) => ({ ...item, date: new Date(item.date) }));
      }
    } catch (error) {
      return [];
    }
    return history;
  }

  /******* STATIC METHODS ********/

  static getInstace(): HistoryStore {
    if (!HistoryStoreImpl._instance) {
      HistoryStoreImpl._instance = new HistoryStoreImpl();
    }
    return HistoryStoreImpl._instance;
  }
}
